<template>

  <div>
    <time-calendar :view-type="calendarMode"></time-calendar>
  </div>

</template>

<script>

import timeCalendar from "../../components/calendar/timeCalendar";

export default {
  name: "timeCalendarTest",

  components: {
    timeCalendar,
  },

  data() {
    return {
      calendarMode: 'week'
    }
  }
}
</script>

<style scoped>

</style>
