<template>
  <div class="time-calendar">
    <div class="time-calendar-main">
      <FullCalendar
        class="full-calendar"
        ref="fullCalendar"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <span :class="dot(arg.event.id * 1)"></span>
          <b>{{ arg.event.title }}</b>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import zhcnLocale from "@fullcalendar/core/locales/zh-cn";

export default {
  name: "timeCalendar",

  components: {
    FullCalendar,
  },

  props: {
    viewType: String,
    calendarDate: String,
    scheduleList: Array,
  },

  data: function () {
    return {
      calendarOptions: {
        locale: zhcnLocale,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: null,
        height: 560,
        initialView: "timeGridWeek", // timeGridDay
        eventTimeFormat: {
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        },
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        },
        dayHeaderFormat: {
          weekday: "long",
          month: "numeric",
          day: "numeric",
          omitCommas: false,
        },
        slotMinTime: "09:00:00",
        slotMaxTime: "19:00:00",
        allDaySlot: true,
        nowIndicator: true,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventColor: "#b3e5fc",
        eventTextColor: "#494949",
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        events: this.fetchEvent,
        eventChange: this.handleEventChange,
        // eventsSet: this.handleEvents
        /* you can update a remote database when these fire:
        eventAdd:
        eventRemove:
        */
      },
      currentEvents: [],
    };
  },

  mounted() {
    this.setViewType(this.viewType);
  },

  activated() {},

  watch: {
    viewType(val, old) {
      if (val && val !== old) {
        this.setViewType(val);
      }
    },

    calendarDate(val, old) {
      if (val !== old) {
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(val);
      }
    },

    scheduleList(val, old) {
      if (val !== old) {
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.getEventSources()[0].refetch();
      }
    },
  },

  methods: {
    // 设置视图模式为按周还是按日
    setViewType(type) {
      let calendarApi = this.$refs.fullCalendar.getApi();
      if (type === "week") {
        calendarApi.changeView("timeGridWeek");
      } else if (type === "day") {
        calendarApi.changeView("timeGridDay");
      }
      calendarApi.gotoDate(this.calendarDate);
    },

    // 从数据源中整理出日历显示所需格式的事件数组
    fetchEvent(info, cb) {
      const events = Array.prototype.slice
        .call(this.scheduleList)
        .map(function (item) {
          let allDay = false;

          const beginDate = jstool.dateStr(item.beginTime);
          const endDate = jstool.dateStr(item.endTime);
          if (beginDate !== endDate) {
            allDay = true;
          }

          if (!allDay) {
            const startHour = jstool.hourStr(item.beginTime);
            const endHour = jstool.hourStr(item.endTime);
            if (startHour <= "09:00" && endHour >= "18:00") {
              allDay = true;
            }
          }

          let endTime = item.endTime;
          if (allDay) {
            const oneDay = 60 * 60 * 24 * 1000;
            endTime = endTime + oneDay;
          }

          return {
            id: item.id,
            title: item.title,
            start: item.beginTime,
            end: endTime,
            allDay: allDay,
          };
        });

      cb(events);
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    // 在日历上拖拽选择时间范围时触发事件
    handleDateSelect(selectInfo) {
      this.$emit("new-event", {
        startStr: selectInfo.startStr,
        endStr: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });

      // let title = prompt('Please enter a new title for your event')
      // let calendarApi = selectInfo.view.calendar
      // calendarApi.unselect() // clear date selection
      // if (title) {
      //   calendarApi.addEvent({
      //     id: +new Date(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   })
      // }
    },

    handleEventChange(changeInfo) {
      console.info(changeInfo);
      this.$emit("change-event", {
        id: changeInfo.event.id * 1,
        startStr: changeInfo.event.startStr,
        endStr: changeInfo.event.endStr,
        allDay: changeInfo.event.allDay,
      });
    },

    handleEventClick(clickInfo) {
      // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      //   clickInfo.event.remove()
      // }
      this.$emit("click-event", {
        id: clickInfo.event.id * 1,
      });
    },

    handleEvents(events) {
      // this.currentEvents = events
    },

    dot(item) {
      if (typeof item === "number") {
        for (const schedule of this.scheduleList) {
          if (schedule.id === item) {
            item = schedule;
            break;
          }
        }
      }
      if (item.output === "meeting_record") {
        let progress = item.meetingProgress.progress;
        if (progress == 100) {
          return "green-dot";
        } else if (progress == 0) {
          return "red-dot";
        } else {
          return "yellow-dot";
        }
      }
      return "no-dot";
    },
  },
};
</script>

<style scoped lang="scss">
.time-calendar-main {
  margin-top: 0px;

  .time-calendar {
  }

  .time-calendar-main {
  }

  .fc {
    margin: 0 auto;
    font-size: 14px;
  }

  $dotSize: 6px;

  .blue-dot {
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
    margin-right: 2px;
    background-color: #2d8cf0;
    border-radius: 10px;
    vertical-align: middle;
    margin-top: -3px;
  }

  .red-dot {
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
    margin-right: 2px;
    background-color: #ed4014;
    border-radius: 10px;
    vertical-align: middle;
    margin-top: -2px;
  }

  .green-dot {
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
    margin-right: 2px;
    background-color: #19be6b;
    border-radius: 10px;
    vertical-align: middle;
    margin-top: -3px;
  }
  .yellow-dot {
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
    margin-right: 2px;
    background-color: #ffd700;
    border-radius: 10px;
    vertical-align: middle;
    margin-top: -3px;
  }
  .no-dot {
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
    margin-right: 2px;
    vertical-align: middle;
    margin-top: -3px;
  }
}
</style>
